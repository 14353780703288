import isEmail from "validator/es/lib/isEmail"

export function validateEmail(v: string): boolean {
  return !v || isEmail(v, { allow_utf8_local_part: false })
}

/**
 * Checks if the value is a valid alpha string, no numbers or special characters)
 * @param v
 */
export function isAlpha(v: string): boolean {
  return !v || /^[^\d\s_!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/\\]+$/u.test(v)
}
